#loading {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}
#loading svg {
  transform: scale(0.15);
}
.rect_1 {
  animation: ani 1.8s ease;
  opacity: 1;
}
.rect_2 {
  animation: ani_2 1.8s ease ;
  opacity: 1;
}
.rect_3 {
  animation: ani_3 1.8s ease;
  opacity: 1;
}
.rect_4 {
  animation: ani_4 1.8s ease;
  opacity: 1;
}

/* @keyframes ani {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  25% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
}
@keyframes ani_2 {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  35% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  70% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  85% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
}
@keyframes ani_3 {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  40% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  90% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
}
@keyframes ani_4 {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  45% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  90% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  95% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
} */

@keyframes ani {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@keyframes ani_2 {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  70% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    opacity: 0;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@keyframes ani_3 {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
@keyframes ani_4 {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  90% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
}
