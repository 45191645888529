.account-setting {
  	background: #fff;
  	h2 {
  		font-weight: normal;
  		font-size: 25px;
	}
	a.btn-primary{
		color: $color-white!important;
	}
	.nav-link.active{
		color: $color-white!important;
	}
}

.social-setting{
  padding: 10px 0;
  button{
   // all: inherit;
  }
  .btn-fb{
  	border: 1px solid $color-fb;
    height: auto;
    padding: 12px 20px;
    margin: auto;
    color: $color-fb!important;
    font-size: 16px;
  }
  .btn-google{
  	border: 1px solid $color-google;
    height: auto;
    padding: 12px 31px;
    margin: auto;
    color: $color-google!important;
    font-size: 16px;
  }
  .btn-ln{
  	background: inherit;
  	border: 1px solid $color-ln;
    height: auto;
    padding: 12px 24px;
    margin: auto;
    color: $color-ln!important;
    font-size: 16px;
    font-weight: 700;
  }
  h4{
  	font-size: 40px;
  	color: $color-primary;
  }
}