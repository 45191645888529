#start-chat {
    background-color: #FFFFFF;
    border-radius: 14px;
    width:100%;
    height:100%;
}
.start-chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.start-chat-container img{
    width: 170px;
    height: 170px;
    border-radius: 50%;
}
.start-chat-container p{
    font-family: "Poppins",sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #646464;
    line-height: 28px;
    text-align: center;
    margin-top: 2rem;
}
.start-chat-container h2{
    font-family: "Poppins",sans-serif;
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 27px;
    line-height: 47px;
    color: #646464;
    margin-top: 2rem;
}
@media(max-width: 900px) {
  #start-chat {
      display: none;
  }
}
@media(min-width: 1650px) {
    .start-chat-container img{
        width: 200px;
        height: 200px;
    }
    .start-chat-container p{
        font-size: 18px;
    }
    .start-chat-container h2{
        font-size: 33px;
    }
}