.login-page {
  background: #fff;
  h2 {
    font-style: normal;
    font-weight: 300;
    //font-size: 37px;
    //line-height: 45px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: $color-black;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 21.5px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: $color-grey;
    max-width: 83%;
  }
  button.login{
    background: $color-primary;
    box-shadow: 12px 14px 38px -21px $color-green;
    border-radius: 66px;
    letter-spacing: 0.015em;
    font-weight: 600;
    padding: 17px;
  }
  button.sign-up {
    font-style: normal;
    font-weight: 600;
    padding: 11px;
  }
  .box {
    background: $color-white;
    border: 1px solid $color-light-grey;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 6px 10px;
    margin: 9px 0;
    input {
      color: inherit;
      border:0;
      font-size: 14px;
      line-height: 22px;
      outline: none;
      color: $color-light-grey;
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-light-grey;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: red;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: red;
      }
    }
    .input-group-text {
      background: $color-primary;
      box-shadow: 4px 22px 68px -12px $color-primary;
      border-color: inherit;
      border-radius: 9px;
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.015em;
    }
  }
  .l-input{
    padding: 11.5px 10px;
  }

  .socialButton{
    max-width: 89%;
    button{
      font-size: 14px;
      padding: 0 9px;
      border: inherit;
      img{
        height: 60px;
        margin-left: -7px;
        width: 217px;
      }
      .s-img{
        width: 181px;
      }
    }
  }
  form{
    max-width: 83%;
  }

  .help-float-icon{
    position: relative;
    right: 0;
    top: -110px
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 21px;
      line-height: 28px;
    }
    p{
      font-size: 13px;
      line-height: 19px;
    }
    button.login{
      font-size: 16px;
    }
    .btn-fb, .btn-google{
      height: 50px;
      font-size: 18px;
      margin: 15px 10px;
    }
    .help-float-icon{
      top: -36px
    }
    .socialButton{
      button{
        .s-img{
          width: 220px;
          margin-left: -20px;
        }
      }
    }
  }

}