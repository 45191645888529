.settings-chat-container {
    padding:5px 1px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.settings-chat-container div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-top: 0;
    margin-bottom: 0;
    width: 90%;
    margin: 10px auto;
    background: #fdfdfd;
    transition: background .2s ease-in-out;
    cursor: pointer;
    border-radius: 12px;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 4px 4px rgba(243, 243, 243, 0.5)
}
.settings-chat-container div:hover{
    background: #f8f8f8;
}
.settings-chat-container .chat-theme-s span{
    width: 25px;
    height: 25px;
    transform: scale(0.7);
    border-radius: 50%;
}
.settings-chat-container div h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B;
}
.settings-chat-container span {
    font-weight: 300;
    font-size: 22px;
    color: #6B6B6B;
    margin-bottom: 7px;
}

.changing {
    height: calc(100% - 12%);
    width:100%;
    position: relative; 
}
.changing-intro {
    width: 100%;
    justify-content: space-between;
    padding:2rem 35px .9rem 35px;
    display: flex;
}
.changing-intro h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B; 
}
.changing-intro span{
    font-weight: 400;
    font-size: 22px;
    color: #6B6B6B; 
    cursor: pointer;
}
.chat-content-change {
    height: calc(100% - 25%);
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-theme-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3px;
}
.col-theme {
    padding: 12px;
    border-radius: 12px;
    transition: background .2s ease-in-out;
}
.col-theme:hover {
    background: #f0f0f0;
}
.col-theme div{
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-radius: 50%;
    cursor: pointer;
}
.col-theme.active {
    background: #f0f0f0;
}
@media(min-height:600px) {
    .col-theme div{
        width: 25px;
        height: 25px;
    } 
    .color-theme-box {
    grid-gap: 4px;
    }  
}
.chat-name-box input{
    padding:10px 12px;
    border-radius: 14px;
    border: .5px solid #6B6B6B;
    outline: none;
    width: 80%;
    margin-right: 20px;
}
.chat-name-box button{
    padding:10px 8px;
    border-radius: 14px;
    border: .5px solid transparent;
    outline: none;
    width: 20%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}
.chat-name-box {
    display: flex;
    width: 80%;
}
.chat-name-box input, .chat-name-box input::placeholder{
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   font-weight: 400;
   color: #6B6B6B;
}

.delete-opacity-all .delete-box{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 15px;
   color: #6B6B6B;
   font-weight: 400;
   width: 90%;
   margin: 0 auto;
   text-align: center;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 20px;
   color: #6B6B6B;
   font-weight: 400;
}
.delete-opacity-all .delete-box .btn{
   font-family: "Poppins", sans-serif;
   font-size: 12px;
   color: #6B6B6B;
   font-weight: 400;
   padding: 12px 30px;
   border: none;
   outline: none;
   border-radius: 14px;
   margin-left: 7px;
   margin-right:7px ;
   margin-top: 25px;
   cursor: pointer;
}
.delete-opacity-all .delete-box .delete-btn{
   background: #D03030;
   color: white;
}

/* @media (min-width:900px) {

    .settings-chat-container {
        padding:1.1rem 2rem .9rem 2rem;
    }
    
    .settings-chat-container div{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 25px;
        margin-top: 5px;
        margin-bottom: 5px;
        background: #fdfdfd;
        transition: background .2s ease-in-out;
        cursor: pointer;
        border-radius: 12px;
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: 0px 4px 4px rgba(243, 243, 243, 0.5)
    }
    .settings-chat-container div h4{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6B6B6B;
}
.settings-chat-container span {
    font-weight: 300;
    font-size: 30px;
    color: #6B6B6B;
    margin-bottom: 15px;
}
.chat-content-change {
    height: calc(100% - 15%);
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-theme-box {
    width: calc(5 * 45px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}
.col-theme {
    padding: 12px;
    border-radius: 12px;
    transition: background .2s ease-in-out;
}
.col-theme:hover {
    background: #f0f0f0;
}
.col-theme div{
    width: 40px;
    height: 40px;
    border: 3px solid transparent;
    border-radius: 50%;
    cursor: pointer;
}
.col-theme.active {
    background: #f0f0f0;
}

.chat-name-box input{
    padding:12px 15px;
    border-radius: 14px;
    border: .5px solid #6B6B6B;
    outline: none;
    width: 80%;
    margin-right: 20px;
}
.chat-name-box button{
    padding:12px 10px;
    border-radius: 14px;
    border: .5px solid transparent;
    outline: none;
    width: 20%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
}
.chat-name-box {
    display: flex;
    width: 45%;
}
.chat-name-box input, .chat-name-box input::placeholder{
   font-family: "Poppins", sans-serif;
   font-size: 15px;
   font-weight: 400;
   color: #6B6B6B;
}

.delete-opacity-all .delete-box{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 17px;
   color: #6B6B6B;
   font-weight: 400;
}
.delete-opacity-all .delete-box h4{
   font-family: "Poppins", sans-serif;
   font-size: 20px;
   color: #6B6B6B;
   font-weight: 400;
}
.delete-opacity-all .delete-box .btn{
   font-family: "Poppins", sans-serif;
   font-size: 13px;
   color: #6B6B6B;
   font-weight: 400;
   padding: 14px 35px;
   border: none;
   outline: none;
   border-radius: 14px;
   margin-left: 7px;
   margin-right:7px ;
   margin-top: 20px;
   cursor: pointer;
}
.delete-opacity-all .delete-box .delete-btn{
   background: #D03030;
   color: white;
}
} */

@media (min-width: 1150px) {
  .settings-chat-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding:1.3rem 2rem;
      flex-direction: row;
      width: auto;
  }
  .settings-chat-container div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        width: 150px;
        margin-left: 20px;
    }
    .changing-intro span {
        font-size: 20px;
    }
    .changing-intro h4 {
        font-size: 16px;
    }
    .chat-name-box input, .chat-name-box input::placeholder {
        font-size: 15px;
    }
    .chat-name-box input {
        padding: 12px 17px;
        margin-right: 15px;
    }
    .chat-name-box button {
        padding: 10px 8px;
        border-radius: 14px;
        border: 0.5px solid transparent;
        outline: none;
        width: 20%;
        color: white;
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;
    }
    .col-theme div {
        width: 30px;
        height: 30px;
    }
    .chat-content-change {
        height: calc(100% - 20%);
    }
}