#center-bar {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    margin: 0 auto;
    z-index: 10;
    display: none;
}

@media(min-width:1150px) {
    #center-bar {
        width: 73%;
        height: 90%;
        top:0;
        left:0;
        transform: translate(0);
        position: relative;
        z-index: 10;
        display: block;
    }
}
@media(min-width: 1650px) {
    #center-bar {
        width: 73%;
        height: 90%;
    }   
}
