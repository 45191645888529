#message  {
    display: flex;
    align-items: center;
}
#message  div{
    padding: 12px 19px;
    margin-top: 15px;
    border-radius: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 12.8px;
    max-width: 85%;
}
#message  div p{
    margin-bottom: auto;
}
#message  div .message-time{
    float: right;
    margin-top: 3px;
}
.my-message {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
}
.other-message {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}
.my-message div, .my-message div p{
    color: #ECEBF3;
}
.other-message div{
    background-color: #F2F2F2;
}
@media(min-width: 900px) {
    #message  div{
        max-width: 55%;
    }
}
@media(min-width: 1650px) {
    #message  div{
        padding: 13px 20px;
        margin-top: 15px;
        font-size: 14px;
        max-width: 45%;
    } 
}
.line {
    text-decoration: underline;
    cursor: pointer;
}
#message-img {
    display: flex;
    align-items: center;
}
#message-img img{
    width: auto;
    max-width: 350px;
    max-height: 350px;
    height: auto;
    border-radius: 14px;
    margin-top: 15px;
    object-fit: cover;
}
.message-info-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
.message-info-content .message-time{
    margin-left: 5px;
}
.message-info-content div{
   margin-left: 5px;
}
.info div{
    background: transparent;
    color: #6B6B6B;
}
#message-info {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    padding:20px 0;
}
#message-info p, #message-info span {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 13px!important;
    color: #6B6B6B;
    margin-bottom: 0;
}