.draft{
    background-color: white;
    .d-head{
        padding-bottom: 5px;
        border-bottom: 1px solid #D0D0D0;
    }
    .d-list{
        background-color: white;
        box-shadow: 0px 22px 100px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 15px;

    }
    .d-detail{
        background-color: white;
        box-shadow: 0px 22px 100px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 15px;
        
        .draft-head{
            font-weight: 500;
            font-size: 14px;
            line-height: 170%;
            color: #A5A5A5;
        }
    }
}