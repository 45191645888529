.friends {
    height: calc(100% - 120px);
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
}
.no-friends {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14.8px;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 60%;
    text-align: center;
    margin: 2rem auto;
    color: #646464;
}
@media(min-width: 1150px) {
    .friends {
        height: calc(100% - 120px);
        margin-top: 10px;
    }
}
@media(min-width: 1650px) {
    .friends {
        height: calc(100% - 140px);
        margin-top: 0px;
    }
}