.leader-board{
  
  background: $color-white;
  h1{
    span{
      font-weight: 300;
      color: $color-grey;
    }
  }
  .impact-card {
    background: $color-white;
    opacity: 0.8;
    box-shadow: 0px 35px 100px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    height: 100%;
    span {
      font-size: 14px;
    }
    h1 {
      font-weight: 200;
    }
    img{
      width: 65px;
      height: 65px;
      margin-bottom: 5px;
    }
    .spot.active{
      background: linear-gradient(0deg, #40FFBA, #40FFBA), #40FFBA;
    }
    @media only screen and (max-width: 767px) {
      .spot{
        right: 38% !important;
      }
    }
    .spot{
      width: 9px;
      height: 9px;
      border-radius: 50%;
      position: absolute;
      top: 5%;
      right: 42%;
      background: linear-gradient(0deg, #FF5151, #FF5151), #40FFBA;
    }
    .rank-ans{
      color: $color-blue;
    }
    .point-ans{
      color: $color-primary;
    }
    .icon-left{
      position:absolute;
      bottom: -2%;
      left:25%;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: linear-gradient(0deg, #2DB3FF, #2DB3FF);
      box-shadow: 0px 17px 18px rgba(45, 179, 255, 0.3);
      // padding: 10px;
      padding: 14px 10px;
      img{
        height: 20px;
        width: 20px;
        
      }
    }
    .icon-right{
      position:absolute;
      bottom: -2%;
      right:25%;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: linear-gradient(0deg, #F8A519, #F8A519);
      box-shadow: 0px 17px 18px rgba(248, 165, 25, 0.5);
      // padding: 10px;
      padding: 16px 10px;
      img{
        height: 17px;
        width: 24px;
        
      }
    }
  }
  .action-text{
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #B8B8B8;
  }
  .other-users{

    .user-row{
      border-bottom: 1px solid #D0D0D0;
      align-items: baseline;
      padding-bottom:10px;
      margin-bottom: 10px;
      p{
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #3F3F3F;
      }
    }
    .user-row.last{
      border-bottom: 0px ;
    }
    .user-row.active{
      box-shadow: -53px 56px 96px -19px rgba(185, 185, 185, 0.8);
      border-radius: 10px;
      border-bottom: 0px;
    }
    .profile-pic{
      height: 60px;
      width: 60px;
    }
  }
}
.leader-board-old {
  .impact-card {
    background: #FFFFFF;
    box-shadow: -53px 56px 96px -19px rgba(185, 185, 185, 0.8);
    border-radius: 19px;
    span {
      font-size: 14px;
    }
    h3 {
      font-size: 32px;
    }
  }
  .card {
    margin: 10px 0;
    max-height: 450px;
  }
  .card-body {
    overflow-y: auto;
    .comment {
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      padding-left: 30px;
      padding-right: 25px;
      padding-top: 10px;
      h4, p, span {
        display: inline;
      }
      h4 {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .card-body::-webkit-scrollbar {
    width: 0.3em;
  }
  .card-body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .card-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

