.discuss-english{
	h1{
		font-weight: bold;
	    font-size: 24px;
	    line-height: 24px;
	}
	h5{
		font-size: 18px;
		line-height: 24px;
		color: $color-primary;
		padding-bottom: 7px;
	}
	p{
		line-height: 24px;
	}
	background: $color-white;
	.border-bottom{
		border-bottom: 1px dotted $color-light-grey!important;
		margin-bottom: 13px;
		padding: 0 0 10px;
		a{
			line-height: 18px;
		}
	}
}