.profile {
    background: #fff;
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        color: $color-black;
    }
    h3 {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: $color-black;
        margin-bottom: inherit;
        span{
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.05em;
            color: #979796;
        }
    }
    .inline{
        background: #f7f7f7;
        border-radius: 4px;
        display: inline-block;
        margin-right: 15px;
        padding: 10.5px 11px;
    }
    .numbers {
        font-size: 13px;
        line-height: 24px;
        color: #969696;
    }
    .nav-tabs::-webkit-scrollbar {
        display: none;
    }
    .profile-pic{
        position: relative;
        left: -7px;
        margin-bottom: 5px;
    }
    .profile-pic-overlay{
        /*background: $color-black;*/
        position: absolute;
        top: 60px;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        color: #fff;
        a{
            color: $color-white;
            position: relative;
            top: 34px;
            margin: 10px;
            img{
                width: 20px;
            }
        }

    }
    p{
        margin-bottom: 0;
    }
    .info-secondary{
        &::before{
            content: "• ";
        }
    }
    .info-primary{
        margin-left: 10px;
        margin-bottom: 10px;
    }
    /* Hide scrollbar for IE and Edge */
    .nav-tabs {
        -ms-overflow-style: none;
    }
    .btn-primary{
        font-size: 14px;
    }
    .about{
        /*border-bottom: 1px solid #CFCFCF;*/
        .about-detail {
            font-weight: normal;
            font-size: 18px;
            line-height: 170%;
            color: #3F3F3F;
        }
    }
    .follow-btn{
        width: 152px;
        margin-top: -2px;
    }
    .verfied-icon{
        width: 18px;
    }
    .menu-icon{
        width: 24px;
    }
    .bellIcon-Old {
        background: linear-gradient(0deg, $color-primary, $color-primary);
        box-shadow: 0px 2px 20px rgba(91, 70, 249, 0.5);
        border-radius: 50%;
        width: 45px;
        height: 45px;
        padding: 5px 0px;
        display: inline-block;
        margin-left: 50px;
        cursor: pointer;
    }
    .bellIcon {
        cursor: pointer;
        display: inline-block;
        height: 25px;
        margin-left: 40px;
        margin-right: 15px;
        width: 25px;
        margin-top: -4px;
    }
    .stats{
        margin-left: -25px;
        margin-right: -35px;
    }

    .custom-tab{
        .tab-content-head{
            font-weight: 600;
            font-size: 13px!important;
            line-height: 150%;
            color: $color-primary;
            /*margin-bottom: auto;*/
        }
        .nav-tabs{
            border-top: 1px solid #dee2e6;
            height: 48px;
            margin-bottom: auto;
            overflow-x: hidden;
            overflow-y: hidden;
            flex-direction: column;
        }
        .nav-link{
            border:0px;
        }
        .nav-link.active{
            border-bottom:2px solid $color-primary;
        }
        .nav-item{
            // min-width: 150px;
            
            span{
                font-size: 13px;
                line-height: 30px;
                /* identical to box height */
                letter-spacing: -0.02em;
                color: #3F3F3F;
                img{
                    min-width: 16px;
                    max-width: 16px;
                }
                .heading-text{
                    margin-left: 10px;
                }
            }
        }
    }
    .user-name{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3F3F3F;
    }
    .post-date{
        font-weight: 500;
        font-size: 11px;
        line-height: 21px;
        color: #A5A5A5;
    }
    .question, .question *{
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    .upvote{
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #3F3F3F;
        margin-bottom: -5px;
    }
    .action{
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #3F3F3F;
        text-align: right;
        float: right;
    }
    .question-list-tab{
        background: #FFFFFF;
        /*box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);*/
        border: 1px solid $color-light-grey;
        box-sizing: border-box;
        border-radius: 10px;
        .reply-text, .reply-text *{
            font-weight: normal;
            line-height: 170%;
            color: #3F3F3F;
        }
        img:not(.profile-pic){
            max-width: 100%;
        }
        .message-box{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .sub-links{
        display: inline-block;
    }
    .info-text{
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.05em;
        color: #3F3F3F;
    }
    .answer-detail{
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #3F3F3F;
    }

    @media only screen and (max-width: 600px) {
        .title{
            font-size: 21px;
            line-height: 26px;
            display: inline;
        }
        .follow-btn{
            width: auto;
        }
        .bellIcon{
            margin-left: 0;
            width: 35px;
            height: 35px;
            img{
                width: 25px;
                height: 25px;
                top: -3px;
                position: relative;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .question{
            font-size: 16px;
            line-height: 22px;
        }
        .user-name{
            font-weight: 500;
            font-size: 15px;
            line-height: 23px;
            color: #3F3F3F;
        }
        .post-date{
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #A5A5A5;
        }
        .sub-links{
            font-size: 14px;
        }
    }
}
.info-secondary{
    color: #B3B3B3;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 2px!important;
}
.info-primary{
    font-style: normal;
    font-weight: 500;
    font-size: 14px!important;
    line-height: 17px;
    color: $color-black;
}
.custom-collpase{
    .col-heading {
        span{
            img{
                width: 31px;
            }
        }
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: $color-primary;
        border-bottom: 1px solid #D0D0D0;
        padding: 0 0 13px;
    }
}

.hoverCard{
    .btn-sm{
        padding: 0px 10px;
        font-size: 16px;
        &:hover{
            color: $color-white;
        }
    }
}

.edit-profile{
    background: $color-white;
    border-top: 1px solid $color-light-grey;
    padding: 15px 0;
    .step-label{
        div{
            width: 130px;
            font-size: 12px;
        }
    }
    .step-content{
        padding: 6px 40px;
        border: 1px solid $color-light-grey;
        margin: 40px 138px 16px;
    }
    .step-footer{
        margin: 0 138px;
        a{
            padding: 5px 23px;
            &:hover{
                color:$color-white;
                text-decoration: none;
            }
        }
    }
    .form-label{
        font-size: 14px;
    }
    .form-control{
        font-size: 14px;
        border-radius: inherit;
        color: inherit;
    }
    .form-check-input{
        margin-top: 8px;
    }
}

.select-topic{
    h2{
        color: $color-primary;   
    }
    .card{
        border-radius: 20px;
        cursor: pointer;
        margin: 10px 5px;
        &.active{
            background: $color-primary;
            color: $color-white;
        }
        .card-body{
            font-size: 16px;
            .form-check{
                padding-left: unset;
                label{
                    cursor: pointer;
                    width: 100%;
                    padding: 1.25rem;
                }
            }
            .form-check-input{
                display: none;
            }
        }
    }
}