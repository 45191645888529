.find-input {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    background-color: #ECEAFC;
    padding: 1.1rem 1.2rem;
    border-radius: 18px;
    width: 90%;
    margin-top: 1.5rem;
    filter: drop-shadow(0px 4px 4px rgba(226, 226, 226, 0.25));
}
.find-input .MuiSvgIcon-root {
    fill: #625AC0 !important;
    font-size: 1.5rem !important;
}
.find-input svg {
    margin-right: 7px;
}
.find-input .MuiSvgIcon-root {
    font-size: 2rem !important;
}
.find-input input {
    margin-right: 2px;
    background-color: transparent;
    outline: none;
    border: none;
}
.find-input input, .find-input input::placeholder {
    font-size: 14px;
    color: #625AC0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.7px;
}
.find-input input:focus{
   border: none;
}

@media (min-width: 1100px) {
  
    .find-input svg {
        margin-right: 6px;
    }
    .find-input input, .find-input input::placeholder {
        font-size: 14px;
    }
    .find-input .MuiSvgIcon-root {
        font-size: 1.7rem !important;
    }
}
@media (min-width: 1650px) {

    .find-input svg {
        margin-right: 7px;
    }
    .find-input input, .find-input input::placeholder {
        font-size: 1.1rem;
    }
    .find-input .MuiSvgIcon-root {
        font-size: 2rem !important;
    }
}